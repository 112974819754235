import React from "react"
import Link from "gatsby-plugin-transition-link/AniLink"
import TextScramble from "./text_scramble"

import {
  FaGithub as Github,
  FaLinkedin as Linkedin,
  FaEnvelope as Mail,
} from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style={{ color: "white" }}>/wave, I'm</span> <br />
        Marcus.
      </div>
      <div className="h1 code mt-4 mb-3">async {"{"}</div>
      <div className="text-muted mx-5 my-4 h3 info">
        I'm a DevOps Enginer specialized in building developer tooling and platforms that help teams build better software.<br />
        Some of the technologies I actively work with include:<br /><br / >
        <ul>
          <li>Kubernetes</li>
          <li>Docker</li>
          <li>Terraform</li>
          <li>Ansible</li>
          <li>Datadog</li>
          <li>Hashicorp Vault</li>
          <li>VMware</li>
        </ul><br />

        I'm also a big <a style={{ color: "grey" }} href="https://www.modohockey.se">hockey fan</a>{""}, and think dogs are objectively the best pets.
        <br />
        <div style={{ height: "25px" }}>
          <TextScramble
            words={[
              'export default function js() { return "I do JavaScript" }',
              'echo implode(array_filter(["And ", "I ", "do ", "PHP"], function() { return true; }));',
              "print(' '.join('Python do I And'.split(' ')[-1::-1]))",
              "class Skills extends React.Component { constructor(props) { super(props) } }",
              'computed: { skills: () => { return "Vue.JS is sweet" } }',
              "app.on('ready', function() { window.loadURL('http://electronjs.org') })",
              "$skill->content = 'Laravel'; $skill->aptitude = 'Artisan'; $skill->save();",
              'echo "Bash do I And" | awk \'{for(i=NF;i>=1;i--) printf "%s ", $i;print ""}\'',
            ]}
          />
        </div>
      </div>
      <div className="h1 code mt-2 mb-3">{"}"}</div>
      <div className="h1 mt-5">
        <a className="mr-5 icon" href="https://github.com/CmdrSharp">
          <Github />
        </a>

        <a className="mr-5 icon" href="https://www.linkedin.com/in/marcusfrolander/">
          <Linkedin />
        </a>

        <Link className="mr-5 icon" to="/contact">
          <Mail />
        </Link>
      </div>
    </div>
  )
}

import React from "react"

class TextScramble extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text: "",
      chars: "!<>-_\\/[]{}—=+*^?#________",
    }

    this.setText = this.setText.bind(this)
    this.update = this.update.bind(this)
    this.randomChar = this.randomChar.bind(this)
  }

  setText(newText) {
    const length = Math.max(this.state.text.length, newText.length)
    const promise = new Promise(resolve => (this.resolve = resolve))
    this.queue = []

    for (let i = 0; i < length; i++) {
      const from = this.state.text[i] || ""
      const to = newText[i] || ""
      const start = Math.floor(Math.random() * 40)
      const end = start + Math.floor(Math.random() * 40)

      this.queue.push({
        from,
        to,
        start,
        end,
      })
    }

    cancelAnimationFrame(this.frameRequest)
    this.frame = 0
    this.update()
    return promise
  }

  update() {
    let output = ""
    let complete = 0

    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i]

      if (this.frame >= end) {
        complete++
        output += to
      } else if (this.frame >= start) {
        if (!char || Math.random() < 5) {
          char = this.randomChar()

          this.queue[i].char = char
        }
        output += `<span style="color: #555;">${char}</span>`
      } else {
        output += from
      }
    }

    this.setState({
      text: output,
    })

    if (complete === this.queue.length) {
      this.resolve()
    } else {
      this.frameRequest = requestAnimationFrame(this.update)
      this.frame++
    }
  }

  randomChar() {
    return this.state.chars[Math.floor(Math.random() * this.state.chars.length)]
  }

  componentDidMount() {
    if (!this.props.hasOwnProperty("words")) {
      return false
    }

    let counter = 0
    const shuffle = array => array.sort(() => Math.random() - 0.5)
    shuffle(this.props.words)

    const next = () => {
      this.setText(this.props.words[counter]).then(() => {
        setTimeout(next, 2500)
      })

      counter = (counter + 1) % this.props.words.length
    }

    next()
  }

  render() {
    return (
      <span
        style={{
          fontWeight: 100,
          fontSize: "18px",
          height: "25px",
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.text,
        }}
      ></span>
    )
  }
}

export default TextScramble
